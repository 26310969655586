import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'))
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))
export const SHOWTIME_FORMAT_DISPLAY = 'MMM D, YYYY h:mm A'

export const MONTH_DAY_TIME_FORMAT = 'MMM D, h:mm A'
export const SHOWTIME_FORMAT_STORED = 'YYYY-MM-DDTHH:mm'

export const isDisplayShowtime = (date: string) => {
    return dayjs(date, SHOWTIME_FORMAT_DISPLAY).isValid()
}
export const toShowtime = (showtime: string, format = SHOWTIME_FORMAT_DISPLAY): string => {
    if (!showtime) return "";
    const date = dayjs(showtime, SHOWTIME_FORMAT_STORED)
    if (date.isValid()) {
        return date.format(format)
    }
    return showtime
}

export const toShowtimeShort = (showtime: string): string => {
    if (!showtime) return "";
    const date = dayjs(showtime, SHOWTIME_FORMAT_STORED)
    if (date.isValid()) {
        return date.format('ddd MMM D, h:mm A')
    }
    return showtime
}

export const toShowtimeEventPageForReactDatePicker = (showtime: string): string => {
    if (!showtime) return "";
    const date = dayjs(showtime)
    return date.format(SHOWTIME_FORMAT_DISPLAY)
}


export const formatDate = (fullDateString: string | Date, format = SHOWTIME_FORMAT_DISPLAY): string => {
    return dayjs(fullDateString).format(format)
}

export const formatDateStored = (fullDateString: string): string => {
    return dayjs(fullDateString).format(SHOWTIME_FORMAT_STORED)
}

export const fromDateStoredToDate = (date: string, timezone?: string): Date => {
    if (timezone) {
        // @ts-ignore
        return dayjs.tz(date, SHOWTIME_FORMAT_STORED, timezone)
    } else {
        return dayjs(date, SHOWTIME_FORMAT_STORED).toDate()
    }
}

export const formatOffsetDate = (offset: number): string => {
    return dayjs.unix(offset).format(SHOWTIME_FORMAT_DISPLAY)
}
