import dayjs from "dayjs";
import {SHOWTIME_FORMAT_STORED} from "../utils/date";
import {Product, ProductVariant} from "../models/models";

dayjs.extend(require('dayjs/plugin/customParseFormat'))
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

export function productTransformer(product: Product): Product {
    if (product.is_giftcard) {
        return {...product, activeVariants: product.variants} as Product
    }
    // @ts-ignore
    const today = dayjs.tz(dayjs(), product.venueArrangement.venue.timezone)
    const activeVariants = product.variants.filter((v) => {
        try {
            const showtime = getShowtime(product, v)
            // @ts-ignore
            const eventDate = dayjs.tz(showtime, SHOWTIME_FORMAT_STORED, product.venueArrangement.venue.timezone)
            if (eventDate.isValid()) {
                // @ts-ignore
                return eventDate.isSameOrAfter(today, 'date')
            }
            return false;
        } catch (e: any) {
            console.log("Threw timezone parsing exception ", product.title, e)
            return false;
        }
    })
    activeVariants.sort((a, b) => {
        const timeA = a.startsAt
        const timeB = b.startsAt
        if (timeA < timeB) {
            return -1
        } else if (timeA > timeB) {
            return 1
        } else {
            const priceA = a.prices[0].amount
            const priceB = b.prices[0].amount
            if (priceA < priceB) return -1
            else if (priceA > priceB) return 1
            else return 0
        }
    })
    for (const a of activeVariants) {
        if (a.variantParticipants) {
            a.variantParticipants.sort((a, b) => {
                if (a.role === "headliner") return -1
                else if (b.role === "headliner") return 1
                else return 0
            })
        }
    }
    const inactiveVariants = product.variants.filter(v => !activeVariants.some(av => av.id === v.id))
    return {...product, activeVariants, inactiveVariants} as Product
}

export function getShowtime(product: Product, variant: ProductVariant): string {
    return variant.startsAt
}