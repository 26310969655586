import {productTransformer} from "./getProduct";
import {Product} from "../models/models";
import _ from "lodash";

export function productsTransformer(response: { products: Product[] }) {
    if (!response.products || response.products.length === 0) return []
    let result = response.products.map(p => productTransformer(p))
    // We need to do this as we no longer sort products based on date in backend
    result.sort((a, b) => {
        const aMaxStartsAt = _.max(a.activeVariants.filter(v => v.startsAt !== undefined).map(v => v.startsAt)) || '1999-01-01T00:00'
        const bMaxStartsAt = _.max(b.activeVariants.filter(v => v.startsAt !== undefined).map(v => v.startsAt)) || '1999-01-01T00:00'
        return aMaxStartsAt.localeCompare(bMaxStartsAt)
    })
    return result
}