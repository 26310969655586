import {Participant, Product, ProductCategory, ProductsByRegion, Venue} from "../models/models";
import {Region} from "@medusajs/medusa";
import {get} from "./fetch";
import {productsTransformer} from "../transformers/getProducts";
import filterProductsByRegion from "../transformers/filter-products-by-region";

export const additionalStoreProductsRelations: string[] = []
additionalStoreProductsRelations.push("options")
additionalStoreProductsRelations.push("variants")
additionalStoreProductsRelations.push("variants.options")
additionalStoreProductsRelations.push("variants.prices")
additionalStoreProductsRelations.push("venueArrangement")
additionalStoreProductsRelations.push("venueArrangement.venue")
additionalStoreProductsRelations.push("venueArrangement.venue.city")
additionalStoreProductsRelations.push("variants.variantParticipants")
additionalStoreProductsRelations.push("variants.variantParticipants.participant")
additionalStoreProductsRelations.push("variants.seatingChart")
additionalStoreProductsRelations.push("account")
additionalStoreProductsRelations.push("productContentBlocks")
additionalStoreProductsRelations.push("productContentBlocks.contentBlock")

export async function getProductsByRegion(slug: string): Promise<{ productsByRegion: ProductsByRegion[], slugEntity: ProductCategory | Participant | Venue }> {
    if (!slug || slug === 'null') {
        return { productsByRegion: [], slugEntity: {id: '', name: '', handle: '', created_at: new Date()} }
    }
    const regionsData: { regions: Region[] } = await get('/store/regions')
    let products = await get(`/store/products/slug/${slug}`)
    const slugEntity = await get(`/store/slugs/${slug}`);
    products = productsTransformer({products: products.items})
    const productsByRegion = regionsData.regions.map(r => ({region: r, products: filterProductsByRegion(products, r)}))
    return {productsByRegion, slugEntity}
}

export async function getAllProductsByRegion(): Promise<ProductsByRegion[]> {
    const config = JSON.parse(process.env.NEXT_PUBLIC_STORE_CONFIG as string)
    if (config.slug) {
        const  {productsByRegion} = await getProductsByRegion(config.slug)
        return productsByRegion
    }
    const regionsData: {regions: Region[]} = await get('/store/regions')
    const productsByRegion: ProductsByRegion[] = []
    for (const r of regionsData.regions) {
        const ps = (await get(`/store/products?is_giftcard=false&limit=500&region_id=${r.id}`))
        productsByRegion.push({
            products: filterProductsByRegion(productsTransformer(ps), r),
            region: r
        })
    }
    return productsByRegion
}

export async function getAllGiftCardsByRegion(): Promise<ProductsByRegion[]> {
    const regionsData: {regions: Region[]} = await get('/store/regions')
    const productsByRegion: ProductsByRegion[] = []
    for (const r of regionsData.regions) {
        const ps = (await get(`/store/products?is_giftcard=true&limit=500&region_id=${r.id}`))
        productsByRegion.push({
            products: filterProductsByRegion(productsTransformer(ps), r),
            region: r
        })
    }
    return productsByRegion
}

export async function getProductById(id: string): Promise<Product> {
    const {product} = await get(`/store/products/${id}?expand=${additionalStoreProductsRelations.join(",")}`)
    return product
}

export async function getProductBySlug(slug: string) {
    const productId = await getProductIdBySlug(slug)
    return await getProductById(productId)
}

export async function getProductIdBySlug(slug: string): Promise<string> {
    const {data} = await get(`/store/product-ids/handle/${slug}`)
    return data
}